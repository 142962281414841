import React, { useEffect, useContext } from "react";
import CompanyPatients from "./company-patients";
import ComplianceTeamDashboardContext from "../context";
import ComplianceTeamCompanyContacts from "./contacts";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import { Spinner } from "components/ui";

const ComplianceTeamCompanies = () => {
  const filters = useContext(ComplianceTeamDashboardContext);

  const fetchCompanyPatientsApi = useFetch({
    apiFn: () =>
      put(`compliance-team/companies/patients`, {
        TeamName: filters.selectedTeam,
        FromDate: filters.requestedDates.start_date,
        ToDate: filters.requestedDates.end_date
      }),
    defaultValue: [],
    transformError: path(["response", "body", "status"]),
    onError: err => errorMessage(`Failed to get coaches data: ${err}`)
  });

  const fetchCompanyContactsApi = useFetch({
    apiFn: () =>
      put(`compliance-team/company-contacts`, {
        ToDate: filters.requestedDates.end_date,
        FromDate: filters.requestedDates.start_date,
        TeamName: filters.selectedTeam
      }),
    defaultValue: [],
    transformError: path(["response", "body", "status"]),
    onError: err => {
      return errorMessage(`Failed to get coach contacts: ${err}`);
    }
  });

  useEffect(() => {
    if (filters.selectedTeam) {
      fetchCompanyPatientsApi.fetchData();
    }
  }, [filters.selectedTeam]);

  useEffect(() => {
    if (filters.requestedDates.start_date && filters.requestedDates.end_date) {
      fetchCompanyContactsApi.fetchData();
    }
  }, [filters.requestedDates.start_date, filters.requestedDates.end_date]);

  return (
    <div className="compliance-team-dashboard-container">
      {!fetchCompanyPatientsApi.isFetching ? (
        <CompanyPatients
          minimizeAll={filters.minimizeAll}
          data={fetchCompanyPatientsApi.response}
        />
      ) : (
        <Spinner />
      )}
      {!fetchCompanyContactsApi.isFetching ? (
        <ComplianceTeamCompanyContacts
          requestedDates={filters.requestedDates}
          minimizeAll={filters.minimizeAll}
          data={fetchCompanyContactsApi.response}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ComplianceTeamCompanies;
