import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { toggleMute } from "actions/phone";
import MdMic from "react-icons/lib/md/mic";
import MdMicOff from "react-icons/lib/md/mic-off";

const CallControls = ({ isConnected, isMuted, toggleMute, callDuration }) => (
  <React.Fragment>
    {isConnected && (
      <button
        title={"Click To Mute Call"}
        onClick={toggleMute}
        className={`muteButton${isMuted ? " active" : ""}`}
      >
        {isMuted ? <MdMicOff size="1.5em" /> : <MdMic size="1.5em" />}
      </button>
    )}
    {isConnected && (
      <div className={`callDuration${callDuration === null ? " noCall" : ""}`}>
        {callDuration !== null
          ? `${("0" + Math.floor(callDuration / 60)).slice(-2)}:${(
              "0" +
              (callDuration % 60)
            ).slice(-2)}`
          : "00:00"}
      </div>
    )}
  </React.Fragment>
);

CallControls.propTypes = {
  isConnected: PropTypes.bool,
  isMuted: PropTypes.bool,
  toggleMute: PropTypes.func.isRequired,
  callDuration: PropTypes.number
};

export default connect(
  state => ({
    isConnected: selectors.getIsPhoneConnected(state),
    isMuted: selectors.getIsPhoneMuted(state),
    callDuration: selectors.getPhoneCallDuration(state)
  }),
  {
    toggleMute
  }
)(CallControls);
