import React, { useState, useEffect } from "react";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
import useLocalQuery, {
  Table,
  NonSortableHeader
} from "hooks/useQuery/local-query";
import ComplianceTeamCoachesStatusRow from "./compliance-team-coaches-status-row";
import useSignalr from "features/signalR/use-signalr";
import {
  getOnlineUsers,
  joinGroup,
  leaveGroup
} from "features/signalR/actions";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";

const ComplianceTeamCoachesStatus = ({ data, minimizeAll, userOffshore }) => {
  const [isMinimized, setMinimized] = useState(false);

  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);

  const columns = [
    ...(!userOffshore
      ? [
          {
            header: "",
            field: "coach_settings",
            style: { width: 20 }
          }
        ]
      : []),
    {
      header: "Name",
      field: "coach_name",
      style: { width: 250 }
    },
    {
      header: "Online",
      field: "connected_at",
      style: { width: 50 },
      Cell: () => <NonSortableHeader text="Online" />
    },
    {
      header: "Call Duration",
      field: "callConnectedAt",
      style: { width: 50 },
      Cell: () => <NonSortableHeader text="Call Duration" />
    },
    {
      header: "Autodialer Session",
      field: "autodialerOpenedAt",
      Cell: () => <NonSortableHeader text="Autodialer Session" />
    },
    {
      header: "Total Time Online",
      field: "total_time_in_seconds"
    },
    {
      header: "Total Time In Autodialer",
      field: "time_in_queue_seconds"
    },
    {
      header: "Autodialer Usage %",
      field: "autodialer_usage",
      Cell: () => <NonSortableHeader text="Autodialer Usage %" />
    }
  ];

  const { rows, query, queryDispatcher } = useLocalQuery(data, {
    sort: { by: "coach_name", direction: "desc" }
  });

  useSignalr(
    [joinGroup("ClientStatusListener"), getOnlineUsers()],
    [leaveGroup("ClientStatusListener")]
  );

  return (
    <div className="compliance-block compliance-coach-status">
      <div
        className="compliance-block-header"
        onClick={() => setMinimized(!isMinimized)}
      >
        Coaches
        <span>
          <i style={{ padding: "0 2px" }}>
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {<WatchCoachTimeUpdates />}
      {!isMinimized && (
        <div className="block-content">
          <Table
            containerClass="query-table coach-status-table"
            rows={rows}
            query={query}
            queryDispatcher={queryDispatcher}
            TableRow={props => <ComplianceTeamCoachesStatusRow {...props} />}
            rowKey="coach_guid"
            columns={columns}
          />
        </div>
      )}
    </div>
  );
};

ComplianceTeamCoachesStatus.propTypes = {
  data: PropTypes.array,
  minimizeAll: PropTypes.func,
  userOffshore: PropTypes.object
};

const WatchCoachTimeUpdates = () => {
  useSignalr(
    [joinGroup("ClientTimeUpdates")],
    [leaveGroup("ClientTimeUpdates")]
  );
  return null;
};

export default connect(state => ({
  userOffshore: selectors.getUserOffshore(state)
}))(ComplianceTeamCoachesStatus);
