import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { closePhone, openPhone } from "actions/phone";
import { selectors } from "reducers";
import AutoDialerTimer from "./auto-dialer-timer";
import RecordingControls from "./recording-controls";
import CallQualityWarning from "./call-quality-warning";
import PhoneInputDialer from "./phone-input-dialer";
import CallControls from "./call-controls";
import QueueControls from "./queue-controls";
import TransferControls from "./transfer-controls";
import IncomingCall from "./incoming-call";
import HasRolePermission from "components/shared/has-role-permission";

const Phone = ({
  incomingCall,
  isConnected,
  closePhone,
  user,
  openPhone,
  isOpen
}) => {
  React.useEffect(() => {
    openPhone();
  }, []);
  if (incomingCall) {
    let oldTitle = document.title;
    let msg = "Incoming!";
    let timeoutId = false;

    const blink = function () {
      document.title = document.title == msg ? oldTitle : msg; //Modify Title in case a popup

      if (document.hasFocus()) {
        //Stop blinking and restore the Application Title
        document.title = oldTitle;
        clearInterval(timeoutId);
      }
    };
    if (!timeoutId) {
      timeoutId = setInterval(blink, 500); //Initiate the Blink Call
    }
  }
  return isOpen ? (
    <div className="phone no-print">
      <div className="main">
        <div>
          <PhoneInputDialer />
          <CallControls />
        </div>
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "SleepCoach",
            "ServiceAdmin",
            "ServiceCoach",
            "ContractorAdministrator"
          ]}
          renderElse={user.compliance_coach ? <QueueControls /> : null}
        >
          <QueueControls />
        </HasRolePermission>
        <TransferControls />
      </div>
      <IncomingCall />
      {isConnected ? <RecordingControls /> : <AutoDialerTimer />}
      {isConnected && <CallQualityWarning />}
      {!isConnected && (
        <HasRolePermission allowedRoles={["Administrator"]}>
          <div className="closeButton">
            <i onClick={closePhone} title="Close phone" />
          </div>
        </HasRolePermission>
      )}
    </div>
  ) : null;
};

export default connect(
  state => ({
    isOpen: selectors.getIsPhoneOpen(state),
    user: selectors.getUser(state),
    isConnected: selectors.getIsPhoneConnected(state),
    incomingCall: selectors.getIsThereAnIncomingCall(state)
  }),
  {
    closePhone,
    openPhone
  }
)(Phone);

Phone.propTypes = {
  isOpen: PropTypes.bool,
  incomingCall: PropTypes.bool,
  isConnected: PropTypes.bool,
  user: PropTypes.object.isRequired,
  closePhone: PropTypes.func.isRequired,
  openPhone: PropTypes.func.isRequired
};
