import React from "react";
import PropTypes from "prop-types";
import accounting from "accounting";
import OrderTableCard from "./dashboard-cards/orders-table-card";
import HelpfulLinksCard from "./dashboard-cards/helpful-links-card";
import OrdersClaimedCard from "./dashboard-cards/orders-claimed-card";
import PatientsOverview from "./patients-overview";
import AnnouncementsCard from "./dashboard-cards/announcements-card";
import classNames from "classnames";
import compact from "lodash/compact";
import { deSnakeCase, capitalize } from "utils/misc";
import HasAppPermission from "components/shared/has-app-permission";
import HasRolePermission from "components/shared/has-role-permission";

const getTotalOrderCount = orderStats => {
  const total = orderStats.reduce((total, cur) => cur.get("count") + total, 0);
  return isNaN(total) ? "" : total;
};

const getOrderStatusLink = status => {
  if (typeof status !== "string") return null;
  switch (status) {
    case "internal processing":
      return "internal_processing";
    case "location assistance needed ":
      return "location_assistance_needed";
    case "coach follow up":
      return "follow_up";
    case "ivr pending work":
      return null;
    case "walk_ins":
      return null;
    default:
      return status.replace(/\s/g, "_");
  }
};

const displayOrderStatusText = status => {
  switch (status) {
    case "new":
      return "New";
    case "completed":
      return "Completed";
    case "delayed":
      return "Delayed";
    case "pending":
      return "Pending Confirmation";
    case "ready":
      return "Accepted";
    case "follow_up":
      return "Follow Up";
    case "on_hold":
      return "On Hold";
    case "incorrect":
      return "Incorrect";
    case "billed":
      return "Billed";
    case "rejected":
      return "Rejected";
    case "pending_buildout":
      return "Pending Buildout";
    case "location_assistance_needed":
      return "Location Assistance Needed";
    case "ready_for_review":
      return "Ready for Review";
    case "internal_processing":
      return "Internal Processing";
    case "voided":
      return "Voided";
    case "backordered":
      return "Backordered";
    case "pending_fulfillment":
      return "Pending Fulfillment";
    case "pending_shipment":
      return "Pending Shipment";
    case "order_loading":
      return "Order Loading";
    default:
      return status;
  }
};

const createDashboardOrderDataRow = orders => {
  return orders.get("OrderStats").map(order => {
    const status = order.get("status");
    const revenue = order.get("revenue");
    const count = order.get("count");
    return {
      title: (
        <span className="dashboard-order-status-title" title={status}>
          {capitalize(deSnakeCase(displayOrderStatusText(status)), true)}
        </span>
      ),
      pathState: getOrderStatusLink(status),
      count,
      value: revenue.toLocaleString()
    };
  });
};

const createDashboardOrderEstimatedRevenueRow = (orders, userRole) => {
  return ["Administrator", "CompanyAdministrator"].includes(userRole)
    ? {
        title: (
          <span
            className="dashboard-order-status-title"
            title="Estimated Revenue"
          >
            Estimated Revenue
          </span>
        ),
        pathState: null,
        value: accounting.formatMoney(orders.get("estimated_revenue"))
      }
    : null;
};

const DashboardContainer = ({ orders, userRole, dayPicker }) => {
  const dashboardOrderData = {
    rows: compact([
      createDashboardOrderEstimatedRevenueRow(orders, userRole),
      ...createDashboardOrderDataRow(orders)
    ])
  };

  const makeOrdersForPieChart = orders => {
    const pieChartOrders = [];
    for (const property in orders) {
      let newObj = {};
      newObj.value = orders[property];
      newObj.name = property;
      pieChartOrders.push(newObj);
    }
    return pieChartOrders;
  };
  const containerClasses = classNames({
    "admin-dashboard-container": userRole === "Administrator",
    "office-admin-dashboard-container":
      userRole === "OfficeAdministrator" || userRole === "ServiceCoach",
    "company-admin-dashboard-container":
      userRole === "CompanyAdministrator" || userRole === "ServiceAdmin"
  });
  return (
    <>
      <div className={containerClasses}>
        <HasRolePermission contractorCheck={true}>
          <PatientsOverview />
          <HasAppPermission permission="orderSupplies">
            <OrderTableCard
              classProp="order-table"
              dayPicker={dayPicker}
              dashboardOrderData={dashboardOrderData}
              totalOrdersCount={getTotalOrderCount(
                orders.get("OrderStats")
              ).toLocaleString()}
              pieChartOrders={makeOrdersForPieChart(orders.toJS())}
            />
          </HasAppPermission>
          {(userRole === "CompanyAdministrator" ||
            userRole === "ServiceAdmin") && (
            <HasAppPermission permission="orderSupplies">
              <OrdersClaimedCard
                classProp="orders-claimed"
                userRole={userRole}
              />
            </HasAppPermission>
          )}
          <HelpfulLinksCard classProp="helpful-links" userRole={userRole} />
          <AnnouncementsCard
            classProp="announcements"
            isAdmin={userRole === "Administrator"}
          />
        </HasRolePermission>
      </div>
    </>
  );
};

DashboardContainer.propTypes = {
  orders: PropTypes.object,
  userRole: PropTypes.string,
  dayPicker: PropTypes.object
};

export default DashboardContainer;
