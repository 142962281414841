import { eventChannel } from "redux-saga";
import { Device } from "@twilio/voice-sdk";
import { take, call, put } from "redux-saga/effects";
import { Types } from "actions/phone";

const createDeviceChannel = device =>
  eventChannel(emit => {
    device.on(Device.EventName.Registered, () =>
      emit({ type: Types.TWILIO_EVENT_READY })
    );
    device.on(Device.EventName.Destroyed, () =>
      emit({ type: Types.TWILIO_EVENT_OFFLINE })
    );
    // device.on("connect", connection =>
    //   emit({ type: Types.TWILIO_EVENT_CONNECT, connection, device })
    // );
    // device.on("disconnect", () =>
    //   emit({ type: Types.TWILIO_EVENT_DISCONNECT })
    // );
    device.on(Device.EventName.Incoming, twilioCall => {
      console.log("incoming", { twilioCall });
      emit({ type: Types.TWILIO_EVENT_CONNECT, twilioCall, device });
      emit({ type: Types.TWILIO_EVENT_INCOMING, payload: { twilioCall } });
    });
    // device.on("cancel", () =>
    //   emit({ type: Types.TWILIO_EVENT_INCOMING_CANCELED })
    // );
    device.on(Device.EventName.Error, error =>
      emit({ type: Types.TWILIO_EVENT_DEVICE_ERROR, error })
    );
    // device.on("*", (event, ...args) => {
    //   console.log(`Event "${event}" was emitted with arguments:`, args);
    // });
    return () => {
      //TODO: unsubscribe: remove event listeners?
    };
  });

export function* listenDeviceEvents(device) {
  const channel = yield call(createDeviceChannel, device);
  while (true) {
    const action = yield take(channel);
    // console.info(action);
    yield put(action);
  }
}

const createCallChannel = twilioCall =>
  eventChannel(emit => {
    // twilioCall.on("mute", isMuted =>
    twilioCall.on("mute", isMuted => {
      emit({
        type: isMuted ? Types.TWILIO_EVENT_MUTE : Types.TWILIO_EVENT_UNMUTE
      });
    });

    twilioCall.on("accept", call => {
      emit({ type: Types.TWILIO_EVENT_CONNECT, payload: { call } });
    });
    twilioCall.on("disconnect", () =>
      emit({ type: Types.TWILIO_EVENT_DISCONNECT })
    );
    twilioCall.on("disconnect", () =>
      emit({ type: Types.TWILIO_EVENT_DISCONNECT })
    );
    twilioCall.on("error", error =>
      emit({ type: Types.TWILIO_EVENT_CALL_ERROR, error })
    );

    // accept event is triggered on all outgoing calls and connected/answered incoming calls
    twilioCall.on("accept", twillioCall => {
      emit({
        type: Types.TWILIO_EVENT_ACCEPT_INCOMING,
        payload: { ...twillioCall.parameters }
      });
    });
    twilioCall.on("reject", () =>
      emit({ type: Types.TWILIO_EVENT_REJECT_INCOMING })
    );
    twilioCall.on("warning", warning => {
      emit({ type: Types.TWILIO_EVENT_CALL_WARNING, payload: warning });
    });
    twilioCall.on("warning-cleared", () =>
      emit({ type: Types.TWILIO_EVENT_CALL_WARNING_CLEARED })
    );
    return () => {
      // console.info("event channel unsubscribe");
      //TODO: do i need to unsubscribe from events?
    };
  });

export function* listenCallEvents(twilioCall) {
  const channel = yield call(createCallChannel, twilioCall);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}
