import React, { useEffect, useContext } from "react";
import ComplianceTeamCoachesStatus from "./coaches/compliance-team-coaches-status";
import ComplianceTeamCoachContacts from "./coaches/contacts/index";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import { Spinner } from "components/ui";
import ComplianceTeamDashboardContext from "./context";
import ComplianceCoachOrders from "./coach-orders/index";
import ComplianceTeamCoachLogins from "./coaches/compliance-team-coach-logins";

const ComplianceTeamCoaches = () => {
  const filters = useContext(ComplianceTeamDashboardContext);

  const coachInsightsApi = useFetch({
    apiFn: () =>
      put(`compliance-team/coaches`, {
        TeamName: filters.selectedTeam,
        FromDate: filters.requestedDates.start_date,
        ToDate: filters.requestedDates.end_date
      }),
    defaultValue: [],
    transformError: path(["response", "body", "status"]),
    onError: err => errorMessage(`Failed to get coaches data: ${err}`)
  });

  const coachContactsApi = useFetch({
    apiFn: () =>
      put(`compliance-team/coach-contacts`, {
        ToDate: filters.requestedDates.end_date,
        FromDate: filters.requestedDates.start_date,
        TeamName: filters.selectedTeam
      }),
    defaultValue: [],
    transformError: path(["response", "body", "status"]),
    onError: err => {
      return errorMessage(`Failed to get coach contacts: ${err}`);
    }
  });

  const coachOrdersApi = useFetch({
    apiFn: () =>
      put(`compliance-team/coach-orders`, {
        TeamName: filters.selectedTeam,
        FromDate: filters.requestedDates.start_date,
        ToDate: filters.requestedDates.end_date
      }),
    defaultValue: [],
    transformError: path(["response", "body", "status"]),
    onError: err => errorMessage(`Failed to get coaches data: ${err}`)
  });

  const coachLoginHistoryApi = useFetch({
    defaultValue: [],
    apiFn: () =>
      put("compliance-team/coaches-login", {
        ToDate: filters.requestedDates.end_date,
        FromDate: filters.requestedDates.start_date,
        TeamName: filters.selectedTeam
      }),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to pull breakdown: ${error.message}`);
    }
  });

  useEffect(() => {
    if (
      filters.requestedDates.start_date &&
      filters.requestedDates.end_date &&
      filters.selectedTeam
    ) {
      coachInsightsApi.fetchData();
      coachOrdersApi.fetchData();
      coachContactsApi.fetchData();
      coachLoginHistoryApi.fetchData();
    }
  }, [
    filters.requestedDates.start_date,
    filters.requestedDates.end_date,
    filters.selectedTeam
  ]);

  return (
    <div className="compliance-team-dashboard-container">
      {!coachInsightsApi.isFetching ? (
        <ComplianceTeamCoachesStatus
          minimizeAll={filters.minimizeAll}
          data={coachInsightsApi?.response ?? []}
        />
      ) : (
        <Spinner />
      )}
      {!coachOrdersApi.isFetching ? (
        <ComplianceCoachOrders
          data={coachOrdersApi.response ?? []}
          isFetching={coachOrdersApi.isFetching}
          minimizeAll={filters.minimizeAll}
        />
      ) : (
        <Spinner />
      )}

      {!coachContactsApi.isFetching ? (
        <ComplianceTeamCoachContacts
          requestedDates={filters.requestedDates}
          minimizeAll={filters.minimizeAll}
          data={coachContactsApi.response ?? []}
        />
      ) : (
        <Spinner />
      )}

      {!coachLoginHistoryApi.isFetching ? (
        <ComplianceTeamCoachLogins
          requestedDates={filters.requestedDates}
          minimizeAll={filters.minimizeAll}
          data={coachLoginHistoryApi.response ?? []}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ComplianceTeamCoaches;
