import React from "react";
import PropTypes from "prop-types";
import SelectDropdown from "components/forms/select-dropdown";
import DateRange from "components/ui/date-range";
import useDateRange from "hooks/useDateRange";
import MdUnfoldMore from "react-icons/lib/md/unfold-more";
import MdUnfoldLess from "react-icons/lib/md/unfold-less";
import HasRolePermission from "components/shared/has-role-permission";
import ManageTeamLeads from "./manage-team-leads";
import { today } from "utils/dates";

const TeamDashboardHeader = ({
  handleSubmit,
  selectedTeam,
  setSelectedTeam,
  teamNameOptions,
  isFetchingInsights,
  toggleTableMinification,
  minimizeAll,
  isComplianceDashboard
}) => {
  const { startDate, endDate, setStartDate, setEndDate, dateRangeIsValid } =
    useDateRange({
      startDate: today(),
      endDate: today()
    });
  React.useEffect(() => {
    if (selectedTeam)
      handleSubmit(selectedTeam, {
        start_date: startDate,
        end_date: endDate
      });
  }, [selectedTeam]);
  return (
    <div className="team-dashboard-header">
      <span className="team-dashboard-header-title">
        <span>
          {isComplianceDashboard ? "Compliance" : "Resupply"} Team Dashboard:
          {"  "}
        </span>
        <SelectDropdown
          options={teamNameOptions.map(x => ({
            ...x,
            text: x.text.replace(" [Compliance]", ""),
            value: x.value.replace(" [Compliance]", ""),
          }))}
          value={selectedTeam}
          onChange={team => {
            setSelectedTeam(team);
          }}
        />
        <span
          onClick={toggleTableMinification}
          style={{
            fontSize: "20px"
          }}
          className="flat-icon-button ripple"
          title={minimizeAll ? "Expand tables" : "Minimize tables"}
        >
          {minimizeAll ? <MdUnfoldMore /> : <MdUnfoldLess />}
        </span>
      </span>
      <div className="date-range-container">
        <DateRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleSubmit={(start_date, end_date) => {
            handleSubmit(selectedTeam, { start_date, end_date });
          }}
          isValid={dateRangeIsValid && selectedTeam && !isFetchingInsights}
        />
      </div>
      <HasRolePermission allowedRoles={["Administrator"]}>
        <ManageTeamLeads />
      </HasRolePermission>
    </div>
  );
};

TeamDashboardHeader.propTypes = {
  selectedTeam: PropTypes.string,
  teamNameOptions: PropTypes.arrayOf(PropTypes.object),
  setSelectedTeam: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleTableMinification: PropTypes.func.isRequired,
  minimizeAll: PropTypes.bool,
  isFetchingInsights: PropTypes.bool,
  isComplianceDashboard: PropTypes.bool
};

export default TeamDashboardHeader;
