import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOrdersSnapshot } from "action-creators";
import DashboardContainer from "./dashboard-container";
import OrderDayPicker from "./dashboard-cards/order-day-picker";
import { invalidateAdminInsights, fetchAdminInsights } from "actions/insights";
import PhoneNumberPrompt from "components/two-factor-auth/phone-number-prompt";

const AdminDashboardContainer = props => {
  const {
    ordersSnapshot,
    user,
    contactsInsights,
    isFetchingInsights
  } = props;
  // const start_date = "05/10/2019";
  // const end_date = '05/10/2020'
  return (
    <>
      <PhoneNumberPrompt user={user?.toJS()} />
      <DashboardContainer
        userRole={user.get("role")}
        orders={ordersSnapshot}
        dayPicker={<OrderDayPicker {...props} />}
        contactsInsights={contactsInsights}
        isFetchingInsights={isFetchingInsights}
      />
    </>
  );
};

AdminDashboardContainer.propTypes = {
  user: PropTypes.object.isRequired,
  getOrdersSnapshot: PropTypes.func.isRequired,
  ordersSnapshot: PropTypes.object,
  invalidateAdminInsights: PropTypes.func.isRequired,
  fetchAdminInsights: PropTypes.func.isRequired,
  contactsInsights: PropTypes.object,
  isFetchingInsights: PropTypes.bool
};

export default connect(
  state => ({
    user: state.get("user"),
    ordersSnapshot: state.get("ordersSnapshot"),
    contactsInsights: state.getIn(["insights", "contactsInsights"]),
    orders: state.get("orders"),
    isFetchingInsights: state.getIn(["fetch", "GET_INSIGHTS", "isFetching"])
  }),
  {
    getOrdersSnapshot,
    invalidateAdminInsights,
    fetchAdminInsights
  }
)(AdminDashboardContainer);
