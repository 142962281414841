import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { handleAcceptIncoming, handleRejectIncoming } from "actions/phone";

const IncomingCall = ({
  incomingCall,
  incomingCallConnectionObject,
  handleAcceptIncoming,
  handleRejectIncoming
}) =>
  incomingCall ? (
    <div>
      <button
        className="acceptIncomingButton"
        onClick={() => {
          handleAcceptIncoming();
        }}
      >
        Answer
      </button>
      <button
        className="rejectIncomingButton"
        onClick={() => {
          handleRejectIncoming();
        }}
      >
        Reject
      </button>
      <span className="callerIDIncoming">
        From: {incomingCallConnectionObject?.customParameters?.get("From")}
      </span>
    </div>
  ) : null;

IncomingCall.defaultProps = {};

IncomingCall.propTypes = {
  incomingCall: PropTypes.bool,
  incomingCallConnectionObject: PropTypes.shape({
    customParameters: PropTypes.object
  }),
  handleAcceptIncoming: PropTypes.func,
  handleRejectIncoming: PropTypes.func
};

export default connect(
  state => ({
    incomingCall: selectors.getIsThereAnIncomingCall(state),
    incomingCallConnectionObject: selectors.getIncomingCallConnectionObject(
      state
    )
  }),
  {
    handleAcceptIncoming,
    handleRejectIncoming
  }
)(IncomingCall);
