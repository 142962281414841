import { combineReducers } from "redux";
import * as R from "ramda";
import { createSelector } from "reselect";
import { Types } from "actions/phone";
import { toggleReducer, createReducer } from "utils/reducer";
import { getTimeEllapsedInSeconds } from "utils/dates";

const isOpen = toggleReducer(false, [Types.OPEN_PHONE], [Types.CLOSE_PHONE]);
const isReady = toggleReducer(
  false,
  [Types.TWILIO_EVENT_READY],
  [Types.TWILIO_EVENT_OFFLINE]
);
const isConnected = toggleReducer(
  false,
  [Types.TWILIO_EVENT_CONNECT],
  [Types.TWILIO_EVENT_DISCONNECT]
);
const isMuted = toggleReducer(
  false,
  [Types.TWILIO_EVENT_MUTE],
  [Types.TWILIO_EVENT_UNMUTE, Types.TWILIO_EVENT_DISCONNECT]
);
const isThereAnIncomingCall = toggleReducer(
  false,
  [Types.TWILIO_EVENT_INCOMING],
  [
    Types.TWILIO_EVENT_INCOMING_CANCELED,
    Types.TWILIO_EVENT_ACCEPT_INCOMING,
    Types.TWILIO_EVENT_REJECT_INCOMING,
    Types.ACCEPT_INCOMING,
    Types.REJECT_INCOMING
  ]
);
const queues = createReducer([], {
  [Types.QUEUE_UPDATE]: (_, { payload: { queues } }) => queues
});
const clear = R.always("");

const dialerNumber = createReducer("", {
  [Types.SET_DIALER_NUMBER]: (_, { payload: { dialerNumber } }) => dialerNumber,
  [Types.CLOSE_PHONE]: clear,
  [Types.HANGUP]: clear
});
const twilioCallSid = createReducer("", {
  [Types.SET_TWILIO_CALLSID]: (_, { payload }) => payload,
  [Types.TWILIO_EVENT_ACCEPT_INCOMING]: (_, { payload }) => payload?.CallSid,
  [Types.PHONE_CALL]: clear,
  [Types.ANSWER_QUEUE]: clear
});
const patientToCall = createReducer("", {
  [Types.SET_DIALER_NUMBER]: (_, { payload }) =>
    R.propOr("", "patientToCall", payload),
  [Types.CLOSE_PHONE]: clear,
  [Types.TWILIO_EVENT_DISCONNECT]: clear,
  [Types.HANGUP]: clear
});
const queueSelection = createReducer("", {
  [Types.SET_QUEUE_SELECTION]: (_, { payload: { selection } }) => selection
});
const callDuration = createReducer(null, {
  [Types.CALL_DURATION]: (_, { payload: { seconds } }) => seconds,
  [Types.TWILIO_EVENT_DISCONNECT]: R.always(null)
});
const setToPayload = (_, { payload }) => payload;
const isQueueCallActive = createReducer(false, {
  [Types.SET_QUEUE_CALL_ACTIVE]: setToPayload,
  [Types.TWILIO_EVENT_DISCONNECT]: R.F,
  [Types.TWILIO_EVENT_OFFLINE]: R.F
});
const isTransferInProgress = createReducer(false, {
  [Types.SET_TRANSFER_IN_PROGRESS]: setToPayload
});

const transferNumber = createReducer("", {
  [Types.SET_TRANSFER_NUMBER]: setToPayload
});

const isCallInConference = toggleReducer(
  false,
  [Types.SET_CALL_IN_CONFERENCE_TRUE],
  [Types.TWILIO_EVENT_DISCONNECT, Types.TWILIO_EVENT_OFFLINE]
);

const isTransferNumberInConference = toggleReducer(
  false,
  [Types.SET_TRANSFER_NUMBER_IN_CONFERENCE_TRUE],
  [Types.TWILIO_EVENT_DISCONNECT, Types.TWILIO_EVENT_OFFLINE]
);

const defaultIncomingObject = { parameters: { From: "No Caller" } };
const incomingCallConnectionObject = createReducer(defaultIncomingObject, {
  [Types.TWILIO_EVENT_INCOMING]: setToPayload
});

const recordingStatus = createReducer("recording", {
  [Types.SET_PHONE_RECORDING_STATUS]: (state, { payload: { status } }) =>
    status ? status : state,
  [Types.TWILIO_EVENT_CONNECT]: () => "recording"
});

const queueCallToAnswer = createReducer(null, {
  [Types.SET_QUEUE_CALL_TO_ANSWER]: setToPayload
});

const callDirection = createReducer("", {
  [Types.SET_CALL_DIRECTION]: setToPayload
});

const dialedNumber = createReducer("", {
  [Types.SET_DIALED_NUMBER]: setToPayload
});

const connectedIncomingNumber = createReducer("", {
  [Types.SET_CONNECTED_INCOMING_NUMBER]: setToPayload
});

const callQualityWarning = createReducer("", {
  [Types.TWILIO_EVENT_DISCONNECT]: () => "",
  [Types.TWILIO_EVENT_CONNECT]: () => "",
  [Types.TWILIO_EVENT_CALL_WARNING_CLEARED]: () => "",
  [Types.TWILIO_EVENT_CALL_WARNING]: setToPayload
});

const phoneReducer = combineReducers({
  isOpen,
  patientToCall,
  isReady,
  isConnected,
  isMuted,
  queues,
  dialerNumber,
  twilioCallSid,
  queueSelection,
  callDuration,
  isQueueCallActive,
  isTransferInProgress,
  isCallInConference,
  isThereAnIncomingCall,
  incomingCallConnectionObject,
  isTransferNumberInConference,
  recordingStatus,
  queueCallToAnswer,
  callDirection,
  transferNumber,
  dialedNumber,
  callQualityWarning,
  connectedIncomingNumber
});

export default phoneReducer;

const getPhoneState = createSelector(
  [state => state.get("phone")],
  phoneState => phoneState
);
const getPhoneQueues = createSelector(
  [getPhoneState],
  R.pipe(R.propOr([], "queues"), R.sortBy(R.prop("friendly_name")))
);

const getQueue = createSelector(
  [getPhoneQueues, (_, queueName) => queueName],
  (queues, queueName) => R.find(x => x.friendly_name === queueName)(queues)
);

const getPhoneQueueStats = createSelector([getPhoneQueues], queues => ({
  totalInQueue: queues.reduce(
    (accum, { current_size }) => accum + current_size,
    0
  ),
  longestInQueue: queues.reduce(
    (accum, { average_wait_time }) =>
      accum < average_wait_time ? average_wait_time : accum,
    0
  ),
  average_wait_time: R.pipe(
    R.map(R.prop("listCallTimes")),
    R.flatten,
    R.map(getTimeEllapsedInSeconds),
    call_times => {
      if (call_times && call_times.length > 0) {
        const sum = call_times.reduce((acc, curr) => acc + curr);
        return (sum / call_times.length).toFixed(0);
      } else return 0;
    }
  )(queues)
}));

export const selectors = {
  getIsPhoneOpen: createSelector([getPhoneState], R.prop("isOpen")),
  getTwilioCallSid: createSelector([getPhoneState], R.prop("twilioCallSid")),
  getIsPhoneReady: createSelector([getPhoneState], R.prop("isReady")),
  getIsPhoneConnected: createSelector([getPhoneState], R.prop("isConnected")),
  getIsPhoneMuted: createSelector([getPhoneState], R.prop("isMuted")),
  getDialerNumber: createSelector([getPhoneState], R.prop("dialerNumber")),
  getQueueSelection: createSelector([getPhoneState], R.prop("queueSelection")),
  getPhoneCallDuration: createSelector([getPhoneState], R.prop("callDuration")),
  getIsQueueCallActive: createSelector(
    [getPhoneState],
    R.prop("isQueueCallActive")
  ),
  getIsTransferInProgress: createSelector(
    [getPhoneState],
    R.prop("isTransferInProgress")
  ),
  getIsCallInConference: createSelector(
    [getPhoneState],
    R.prop("isCallInConference")
  ),
  getIsTransferNumberInConference: createSelector(
    [getPhoneState],
    R.prop("isTransferNumberInConference")
  ),
  getIsThereAnIncomingCall: createSelector(
    [getPhoneState],
    R.prop("isThereAnIncomingCall")
  ),
  getIncomingCallConnectionObject: createSelector(
    [getPhoneState],
    R.prop("incomingCallConnectionObject")
  ),
  getRecordingStatus: createSelector(
    [getPhoneState],
    R.prop("recordingStatus")
  ),
  getQueueCallToAnswer: createSelector(
    [getPhoneState],
    R.prop("queueCallToAnswer")
  ),
  getCallDirection: createSelector([getPhoneState], R.prop("callDirection")),
  getTransferNumber: createSelector([getPhoneState], R.prop("transferNumber")),
  getDialedNumber: createSelector([getPhoneState], R.prop("dialedNumber")),
  getConnectedIncmingNumber: createSelector(
    [getPhoneState],
    R.prop("connectedIncomingNumber")
  ),
  getCallQualityWarning: createSelector(
    [getPhoneState],
    R.prop("callQualityWarning")
  ),
  getPatientToCallGuid: createSelector(
    [getPhoneState],
    R.prop("patientToCall")
  ),
  getPhoneQueues,
  getQueue,
  getPhoneQueueStats
};
