import { select, put, take, fork, cancel, takeEvery } from "redux-saga/effects";
import { selectors } from "reducers";
import { listenCallEvents } from "./event-handler";
import { handleCallConnection } from "./connection-handler";
import { Types } from "actions/phone";

import * as Sentry from "@sentry/react";

function* handleIncomingCall(device, { payload: { twilioCall } }) {
  try {
    // console.log("handle incoming");
    // console.log({ call });
    // const dialerIsActive = yield select(selectors.getIsAutoDialerEnabled);
    // const from = call.parameters.From.replace("+1", "");
    // const autoAnswerIncoming = call?.customParameters?.get("auto_answer");
    const from = twilioCall?.customParameters?.get("From")?.replace("+1", "");
    console.log({ from } )
    // console.log({ autoAnswerIncoming, dialerIsActive, from });
    // const queueCallToAnswer = yield select(state =>
    //   selectors.getQueueCallToAnswer(state)
    // );
    // const thisIsTheQueuedCall =
    //   queueCallToAnswer && queueCallToAnswer.from === from;
    // task to watch accept or reject call
    const callTask = yield fork(listenCallEvents, twilioCall, device);
    const callActions = yield fork(handleCallConnection, twilioCall, device);
    // if (dialerIsActive && autoAnswerIncoming) {
    twilioCall.accept();
    yield put({ type: Types.TWILIO_EVENT_ACCEPT_INCOMING });
    // }

    // if (dialerIsActive && autoAnswerIncoming) {
    // call.accept();
    // yield put({ type: Types.TWILIO_EVENT_ACCEPT_INCOMING });
    // }
    yield put({ type: Types.SET_CALL_DIRECTION, payload: "inbound" });
    yield put({ type: Types.SET_CONNECTED_INCOMING_NUMBER, payload: from });

    yield take(Types.TWILIO_EVENT_DISCONNECT);

    yield cancel(callActions);
    yield cancel(callTask);
  } catch (error) {
    console.log(error);
    Sentry.withScope(scope => {
      scope.setTag("location", window.location.href);
      scope.setTag("incomingCall", true);
      Sentry.captureException(error);
    });
  }
}

export default function* watchIncomingCall(device) {
  yield takeEvery(Types.TWILIO_EVENT_INCOMING, handleIncomingCall, device);
}
